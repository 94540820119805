import { Location } from "@/api/interface/location"
import { SciSettings, SelfCheckIn } from "@/api/interface/self-check-in"
import { convertScheduleDayToFormDay } from "@/components/pages/manage/self-check-in/utils"

const SciSyncRules = {
    useWaitlistHours: {
      checkShouldSync: (
        settings: SciSettings,
        isUsingCustomQueueSelector: boolean,
        customQueueCFId: string,
      ): boolean => {
          return settings.hasOwnProperty('useWaitlistHours')
      },
      buildSyncedValue: (settings: SciSettings): SciSettings['useWaitlistHours'] => {
        return settings.hasOwnProperty('useWaitlistHours') ? settings.useWaitlistHours : true
      },
    },
    publicFields: {
      checkShouldSync: (
        settings: SciSettings,
        isUsingCustomQueueSelector: boolean,
        customQueueCFId: string,
        isUsingBothQueueSelectors?: boolean
      ): boolean => {
        const { publicFields } = settings
        return isUsingCustomQueueSelector && !publicFields.queueSelect && !isUsingBothQueueSelectors
      },
      buildSyncedValue: (
        settings: SciSettings,
        isUsingCustomQueueSelector: boolean,
        isUsingBothQueueSelectors: boolean
      ): SciSettings['publicFields'] => {
        const { publicFields } = settings
        return {
          ...publicFields,
          queueSelect: isUsingCustomQueueSelector && !isUsingBothQueueSelectors ? false : publicFields.queueSelect
        }
      },
    },
    customFields: {
      checkShouldSync: (
        settings: SciSettings,
        isUsingCustomQueueSelector: boolean,
        customQueueCFId: string
      ): boolean => {
        const { customFields } = settings
        return isUsingCustomQueueSelector && customFields?.hasOwnProperty(customQueueCFId)
      },
      buildSyncedValue: (
        settings: SciSettings,
        isUsingCustomQueueSelector: boolean,
        customQueueCFId: string
      ): SciSettings['customFields'] => {
        const { customFields } = settings

        if (isUsingCustomQueueSelector) {
          return customFields?.hasOwnProperty(customQueueCFId) ? customFields : { ...customFields, [customQueueCFId]: true }
        }
        return customFields
      }
    }
}

export const shouldSyncSciSettings = (
  sci: SelfCheckIn,
  isUsingCustomQueueSelector: boolean,
  customQueueCFId: string = ''
) => {
  if (!sci) return false
  const { settings } = sci

  return !Object.keys(SciSyncRules).reduce((acc, d) => {
    const res = SciSyncRules[d].checkShouldSync(
      settings,
      isUsingCustomQueueSelector,
      customQueueCFId
    )
    acc = acc && res
    return acc
  }, true)
}

export const buildSyncedSciSettings = (
  sci: SelfCheckIn,
  isUsingCustomQueueSelector: boolean,
  customQueueCFId: string = '',
  isUsingBothQueueSelectors: boolean = false
) => {
  if (!sci) return null
  const { settings } = sci

  // add more synced items here as needed
  const newUseWaitlistHours = SciSyncRules.useWaitlistHours.buildSyncedValue(sci.settings)
  const newPublicFields = SciSyncRules.publicFields.buildSyncedValue(settings, isUsingCustomQueueSelector, isUsingBothQueueSelectors)
  const newCfs = SciSyncRules.customFields.buildSyncedValue(settings, isUsingCustomQueueSelector, customQueueCFId)

  return {
    ...sci,
    settings: {
      ...sci.settings,
      useWaitlistHours: newUseWaitlistHours,
      publicFields: newPublicFields,
      customFields: newCfs,
    }
  }
}

export const getSharedSci = (selfCheckIns: SelfCheckIn[]) => {
  return selfCheckIns.find((sci) => sci.sciableType === 'business')
}

export const getLocationSci = (selfCheckIns: SelfCheckIn[], locationId: Location['id']) => {
  const locationSci = selfCheckIns.find((sci) => sci.sciableId === locationId)
  return {
    locationSci,
    isLocationSciDeleted: !!locationSci?.deletedAt
  }
}

/**
 * SCI settings page utils
 */
export const hydrateSelfCheckIn = (sci: SelfCheckIn, emptySci: SelfCheckIn) =>
  sci
    ? {
        ...sci,
        settings: { ...emptySci.settings, ...sci.settings },
        scheduleDays: sci.schedule?.items?.map((x) =>
          convertScheduleDayToFormDay(x)
        ),
        geofence: { ...emptySci.geofence, ...sci?.geofence },
      }
    : null

export const getViewSci = (
  sharedSci: SelfCheckIn,
  locationSci: SelfCheckIn,
  hasOnlyOneLocation: boolean,
  isUsingSharedSettings: boolean,
  isLocationSciDeleted: boolean,
) => {
  if (hasOnlyOneLocation || isUsingSharedSettings) {
    return sharedSci
  }

  return !locationSci || isLocationSciDeleted ? sharedSci : locationSci
}

export const getRelatedScis = (
  selfCheckIns: SelfCheckIn[],
  locationId: Location['id'],
  hasOnlyOneLocation: boolean,
  isUsingSharedSettings: boolean,
  emptySci: SelfCheckIn
) => {
  const { locationSci, isLocationSciDeleted } = getLocationSci(selfCheckIns, locationId)
  const sharedSci = hydrateSelfCheckIn(
    getSharedSci(selfCheckIns),
    emptySci
  )
  const viewSci = hydrateSelfCheckIn(
    getViewSci(sharedSci, locationSci, hasOnlyOneLocation, isUsingSharedSettings, isLocationSciDeleted),
    emptySci
  )

  return {
    sharedSci,
    locationSci: hydrateSelfCheckIn(locationSci, emptySci),
    isLocationSciDeleted,
    viewSci, 
  }
}

export const convertSettingsApiToDisplay = (sci: SelfCheckIn): SelfCheckIn => {
  if (!sci) {
    return
  }

  const { settings } = sci
  return {
    ...sci,
    settings: {
      ...settings,
      recognizeRepeatGuests: settings.require2FA && settings.require2FA !== 'none',
    },
  }
}

export const convertSettingsDisplayToApi = (settings: SciSettings): SciSettings => {
  const { recognizeRepeatGuests, ...rest } = settings

  return {
    ...rest,
    require2FA: recognizeRepeatGuests ? 'returning' : 'none',
  }
}
